import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/src/templates/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="">
  <p className="mar-b">Does reading the WCAG 2.0 Guidelines put you to sleep?</p>
  <p>Have you ever felt like you were sacrificing good design to make a site accessible?</p>
  <p>Have you ever thought “man, web accessibility is the most <strong>inaccessible</strong> web development topic”?</p>
  <p className="mar-b"><em>Accessible Web Design</em> is a short book I’m writing that will help you:</p>
  <ul>
  <li>Develop a repertoire of accessible interactive components you can use on any project</li>
  <li>Build projects that screen-reader users can use and&nbsp;<strong>enjoy</strong></li>
  <li>Understand when and how to correctly use <code>aria</code>&nbsp;attributes</li>
  <li>Integrate accessible practices through out your development workflow</li>
  <li>Be more involved in the design and strategy phases of your projects</li>
  <li>Become an accessibility <strong>expert</strong> for your users, coworkers, clients</li>
  </ul>
  <p>If you spend just a few hours learning the basic principles that make the web accessible, you can save days of debugging and testing down the road. I’ll cover how to do this and more with <strong>clear</strong>, <strong>simple</strong> and <strong>fun</strong> examples.</p>
  <p>Send me your email address and I’ll let you know when it’s ready!</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      